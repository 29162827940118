import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoolgePlaceService {

  private sHttp: HttpClient;

  constructor(http: HttpClient) {
    this.sHttp = http;
  }

  getPlaces(text: string): Observable<any> {
    return this.sHttp.get('/integrations/google-place/places/' + text);
  }
}