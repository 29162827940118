import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { MessageScheduled } from '../models/message-scheduled.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageScheduledService extends AbstractService<MessageScheduled> {

  getURLBase(): string {
    return '/message-scheduled';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  override preCreate(obj: MessageScheduled): void {
    delete obj?._id;
  }
  override preUpdate(obj: MessageScheduled): void {
  }

  verifyMessageScheduledByAttendance(idAttendance: string): Observable<boolean>{
    return super.get(`/by/attendance/${idAttendance}`);
  }
  
}
