import { Attendance } from "../models/attendance.model";
import { Channel } from "../models/channel.model";
import { Company } from "../models/company.model";
import { Contact } from "../models/contact.model";
import { ChannelTypeEnum } from '../models/enum/channel.type';
import { MessageTypeEnum } from "../models/enum/message.type.enum";
import { GooglePlaceType } from "../models/google-place.model";
import { Message } from "../models/message.model";
import { User } from "../models/user.model";

export class MessageHelper {

  public static createMessageFromAttendance(
    attendance: Attendance,
    type: MessageTypeEnum,
    content: string,
    contentType: string,
    idUser: string,
    caption?: string,
    vcard?: Contact,
    isReply?: boolean,
    messageReplyed?: Message,
    place?: GooglePlaceType
  ): Message {

    const channel = attendance.channel;

    const message = {} as Message;
    message.attendance = <Attendance>{ _id: attendance._id };
    message.company = <Company>{ _id: attendance.company._id };
    message.channel = <Channel>{ _id: attendance.channel._id };
    message.from = this.getTypeAndMetadataChannel(channel);
    message.to = attendance.contact.phone;
    message.contact = <Contact>{ _id: attendance.contact._id };
    message.type = type;
    message.updatedAt = new Date();
    message.content = content;
    message.contentType = contentType;
    message.user = { _id: idUser } as User;

    if (caption) {
      message.caption = caption
    };

    if (isReply) {
      message.reply = messageReplyed;
    };

    if (type === MessageTypeEnum.contacts) {
      message.vcardContact = vcard;
    };

    if (type === MessageTypeEnum.location) {
      message.metadata = {};
      message.metadata.place = place;
    };

    return message;
  }

  public static getGreeting(): string {
    const currentHour = new Date().getHours();
    if (currentHour >= 6 && currentHour < 12) {
      return 'Bom dia';
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'Boa tarde';
    } else {
      return 'Boa noite';
    }
  }

  public static createReactToMessage(
    attendance: Attendance,
    type: MessageTypeEnum,
    content: string,
    idUser: string
  ): Message {
    const channel = attendance.channel;

    const message = {} as Message;
    message.attendance = <Attendance>{ _id: attendance._id };
    message.company = attendance.company;
    message.channel = <Channel>{ _id: attendance.channel._id };
    message.from = this.getTypeAndMetadataChannel(channel);
    message.to = attendance.contact.phone;
    message.contact = <Contact>{ _id: attendance.contact._id };
    message.type = type;
    message.updatedAt = new Date();
    message.content = content;
    message.contentType = null;
    message.user = { _id: idUser } as User;

    return message;
  }

  private static getTypeAndMetadataChannel(channel: Channel): string {
    if (channel.type === ChannelTypeEnum.CLOUD_API) {
      return channel.metadata.phoneNumberInfo.display_phone_number;
    } else if (channel.type === ChannelTypeEnum.EVOLUTION_API) {
      return channel.metadata['ownerJid'].replace(/[^0-9]/g, '');
    } else {
      return channel.metadata['jid'].replace(/^\d+(?=:)|\d+/g, '');
    }
  }

}
