<div class="w-full">
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
  </svg>

  <h1 class="font-black text-[20px] text-black mt-2.5">{{isNew ? 'Adicionar' : 'Alterar'}} contato</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados e crie um novo contato</h6>

  <form [formGroup]="formGroup" style="max-height: 465px; overflow-y: auto; overflow-x: hidden; padding: 5px;">
    <div class="flex w-full flex-col">
      <div class="flex gap-2">
        <div class="flex flex-col flex-1">
          <label class="font-medium text-black">Nome *</label>
          <input class="input-field" maxlength="100" formControlName="name" placeholder="Digite o nome..." required>
          <field-error-component [field]="'name'" [label]="'Nome'" [formGroup]="formGroup">
          </field-error-component>
        </div>
        <div class="flex flex-row flex-1 w-100 gap-2">
          <div class="flex flex-col w-100">
            <label class="font-medium text-black">Email</label>
            <input class="input-field" maxlength="100" formControlName="email" placeholder="Digite o email...">
          </div>
        </div>
      </div>
      <div class="flex flex-row flex-1 w-100 mt-[15px] gap-2">
        <div class="flex flex-col w-100">
          <label class="font-medium text-black">CPF</label>
          <input class="input-field" maxlength="100" formControlName="tin" [mask]="'000.000.000-00'"
            placeholder="Digite o CPF...">
        </div>
      </div>
      <div class="flex flex-row flex-1 w-full mt-[15px] gap-2">
        <div class="flex flex-col w-[50%]">
          <label class="font-medium text-black">Sexo</label>
          <mat-select class="input-field" formControlName="gender">
            @for (gender of listGenders; track gender) {
            <mat-option [value]="gender">{{ gender === 'MALE' ? 'Masculino' : gender === 'FEMALE' ? 'Feminino' : 'Outro'
              }}</mat-option>
            }
          </mat-select>
        </div>
        <div class="flex flex-col w-[50%]">
          <label class="font-medium text-black">Data de Nascimento</label>
          <div id="input-phone" class="flex pl-2 items-center w-[100%] h-[100%] border rounded-lg">
            <input class="w-[100%] h-[100%] rounded-xl bg-white outline-none" [matDatepicker]="picker"
              formControlName="birthDate" placeholder="Selecione uma data...">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="flex flex-row flex-1 w-full mt-[15px] gap-2">
        <div class="flex flex-col w-[50%]">
          <label class="font-medium text-black">Empresa</label>
          <input type="text" class="input-field" formControlName="contactCompany"
            placeholder="Empresa que o contato trabalha...">
        </div>
        <div class="flex flex-col w-[50%]">
          <label class="font-medium text-black">Cargo</label>
          <input type="text" class="input-field" formControlName="contactPosition" placeholder="Digite o cargo...">
        </div>
      </div>
      <div *ngIf="(isAttendant() && !currentUser?.clientVisibilitys) || (!isAttendant() && (isSupervisor() || isAdmin()))">
        <div class="flex flex-col w-full mt-[15px] gap-2">
          <label class="font-medium text-black">Telefone *</label>
          <div class="flex flex-row flex-1 w-100 justify-center items-center gap-2">
            <select class="form-select custom-select" aria-label="Default select example"
              (change)="onCountryChange($event)" formControlName="country">
              <option [value]="null" selected>
                Selecione
              </option>
              @for (country of countries; track $index) {
              <option [value]="country.code">{{ selectedCountryCode === country.code ? country.flag + ' ' +
                (country.code)
                : country.name}}</option>
              }
            </select>
            <input (keypress)="keyPressOnlyNumbers($event)" class="input-field w-full" maxlength="25"
              formControlName="phone" placeholder="Digite o telefone..." [mask]="phoneMask" required>
          </div>
          <field-error-component [field]="'phone'" [label]="'Telefone'" [formGroup]="formGroup">
          </field-error-component>
        </div>
      </div>

      <div class="flex flex-row flex-1 w-100 mt-[15px] gap-2">
        <div class="flex flex-col w-[50%]">
          <label class="font-medium text-black">CEP</label>
          <input [mask]="'00.000-000'" class="input-field" formControlName="zip" placeholder="Buscar pelo CEP..."
            (ngModelChange)="onCep($event)">
        </div>
        <div class="flex flex-col w-[50%]">
          <label class="font-medium text-black">Logradouro</label>
          <input class="input-field" formControlName="address" placeholder="Digite o logradouro...">
        </div>
      </div>
      <div class="flex flex-row flex-1 w-100 mt-[15px] gap-2">
        <div class="flex flex-col w-50">
          <label class="font-medium text-black">Cidade</label>
          <input class="input-field" formControlName="city" placeholder="Digite a cidade...">
        </div>
        <div class="flex flex-col w-50">
          <label class="font-medium text-black">Estado</label>
          <input class="input-field" maxlength="2" formControlName="state" placeholder="Digite a UF...">
        </div>
      </div>
      <div class="flex gap-2 mt-[15px]">
        <!-- <div class="flex flex-col fixed-width">
          <label class="font-medium text-black">Grupos</label>
          <mat-select class="input-field" formControlName="groups" multiple placeholder="Selecione">
            <div class="w-full h-[30px] p-2 mb-2">
              <input class="input-select-filter input-field" type="text" (focus)="onFocusGroup()"
                [formControl]="filterControlGroup" placeholder="Pesquise por um grupo...">
            </div>
            @for (item of allGroups; track item) {
            <mat-option [value]="item._id">{{ item.name }}</mat-option>
            }
          </mat-select>
        </div> -->
        <div class="flex flex-col fixed-width">
          <label class="font-medium text-black">Etiquetas</label>
          <mat-select class="input-field" formControlName="tags" multiple>
            <div class="input-select-filter w-full p-2 mb-2">
              <input class="input-select-filter input-field" type="text" (focus)="onFocusTags()"
                [formControl]="filterControlTags">
            </div>
            @for (item of allTags; track item) {
            <mat-option [value]="item._id">{{item.title}}</mat-option>
            }
          </mat-select>
        </div>
        <div class="flex flex-col fixed-width">
          <label class="font-medium text-black">Campos Personalizados</label>
          <mat-select class="input-field" formControlName="selectedFields"
            (selectionChange)="updateCustomFields($event.value)" multiple>
            <div class="w-full h-[30px] p-2 mb-2">
              <input class="input-select-filter input-field" type="text" (focus)="onFocusCustomFields()"
                [formControl]="filterCustomField">
            </div>
            @for (item of allFieldsCustom; track item) {
            <mat-option [value]="item._id">{{item.name}}</mat-option>
            }
          </mat-select>
        </div>
      </div>
      <div formArrayName="customFields">
        <div class="w-full gap-4" *ngFor="let field of customFields.controls; let i = index" [formGroupName]="i">
          @switch (getFieldType(field.get('_id').value)) {
          @case ('TEXT') {
          <div class="mt-[15px] mb-[15px]">
            <label class="font-medium text-black">{{ getFieldName(field.get('_id').value) }}</label>
            <input type="text" class="input-field w-full" formControlName="value">
          </div>
          }
          @case ('NUMBER') {
          <div class="mt-[15x] mb-[15px]">
            <label class="font-medium text-black">{{ getFieldName(field.get('_id').value) }}</label>
            <input type="number" class="input-field" formControlName="value">
          </div>
          }
          @case ('FREETEXT') {
          <div class="mt-[15px] mb-[15px]">
            <label class="font-medium text-black">{{ getFieldName(field.get('_id').value) }}</label>
            <textarea class="input-textarea h-[80px]" style="width: 100%; resize: none; outline: none;" maxlength="320"
              formControlName="value"></textarea>
          </div>
          }
          @case ('DATE') {
          <div class="mt-[15px] flex mb-[15px]">
            <div class="flex flex-col w-full">
              <label class="font-medium text-black">{{ getFieldName(field.get('_id').value) }}</label>
              <div id="input-phone" class="bg-white flex pl-2 items-center w-[100%] h-[100%] border rounded-lg">
                <input class="w-[100%] h-[100%] rounded-xl bg-white outline-none" [matDatepicker]="picker"
                  formControlName="value">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </div>
            </div>
          </div>
          }
          }
        </div>
      </div>
    </div>
  </form>
</div>
<div class="flex justify-between items-center w-full gap-2 px-0 mt-4">
  <button
    class="h-[45px] flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[50%]"
    (click)="close()">Cancelar</button>
  <button
    class="h-[45px] px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[50%]"
    (click)="save()">
    {{ !loading ? (isNew ? 'Adicionar' : 'Alterar') : '' }}
    <mat-spinner class="mr-4" [diameter]="25" [color]="'accent'" mode="indeterminate" *ngIf="loading"></mat-spinner>
  </button>
</div>
