export const environment = {
  production: true,
  apiUrl: 'https://hml-api.trendmulti.com.br/api',
  baseUrl: 'https://hml-api.trendmulti.com.br',
  trendWebAppUrl: 'https://hml-app.trendmulti.com.br',
  trendWebAdminUrl: 'https://hml-admin.trendmulti.com.br',
  aws: {
    bucketName: 'trendmulti',
    accessKeyId: 'AKIAYM2KGCQAUXMADXEM',
    secretAccessKey: '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',
    region: 'us-east-1',
  },
  firebase: {
    apiKey: 'AIzaSyDwaQRKkoYjB2wABnzOyTTdsyKloHSO-LA',
    authDomain: 'zapteck-2707e.firebaseapp.com',
    databaseURL: 'https://zapteck-2707e.firebaseio.com',
    projectId: 'zapteck-2707e',
    storageBucket: 'zapteck-2707e.appspot.com',
    messagingSenderId: '1065124905487',
    appId: '1:1065124905487:web:151104c20ff06dcab2197a',
    measurementId: 'G-JHRXV6S0LG',
  },
}
