<div class="flex justify-between items-start gap-2">
    <div class="flex flex-col gap-2">
        <svg id="Camada_2" data-name="Camada 2" xmlns="http://www.w3.org/2000/svg" width="56" height="56"
            viewBox="0 0 97.64 97.64">
            <defs>
                <style>
                    .cls-1 {
                        fill: #d1fadf;
                    }

                    .cls-1,
                    .cls-2,
                    .cls-3 {
                        stroke-width: 0px;
                    }

                    .cls-2 {
                        fill: #ecfdf3;
                    }

                    .cls-3 {
                        fill: #039855;
                    }
                </style>
            </defs>
            <g id="Camada_1-2" data-name="Camada 1">
                <g id="icon-image">
                    <g>
                        <circle class="cls-2" cx="48.82" cy="48.82" r="48.82" />
                        <circle class="cls-1" cx="48.82" cy="48.82" r="34.89" />
                    </g>
                    <g>
                        <path class="cls-3"
                            d="m48.53,65.56c-5.0,0-10.01.03-15.01-.07-3.99-.27-5.41-1.37-5.43-5.24-.41-7.5-0.48-15.01.05-22.51.25-3.52,1.47-4.98,4.98-5.0,10.35-.47,20.69-.47,31.04,0,3.36.16,4.89,1.46,4.92,4.71.72,7.73.67,15.46.04,23.19-.27,3.31-1.51,4.78-4.81,4.83-5.23.74-10.46.2-15.69.21Zm-16.12-28.53v24.16h32.35v-24.16h-32.35Z" />
                        <path class="cls-3"
                            d="m36.04,57.14c1.92-2.48,3.55-4.59,5.31-6.87,1.75,1.66,3.3,3.13,5.14,4.88,2.39-2.62,4.72-5.18,7.25-7.95,2.44,3.27,4.78,6.41,7.42,9.94h-25.12Z" />
                        <circle class="cls-3" cx="44.69" cy="42.73" r="2.65" />
                    </g>
                </g>
            </g>
        </svg>

        <h1 class="font-black text-[20px] text-black">Preview de mídia</h1>
        <h6 class="text-zinc-400 font-light text-sm">{{ textDescription }}</h6>
    </div>
    @if (mode === 'view') {
    <div class="icon-chat" (click)="onCancel()">
        <mat-icon>clear</mat-icon>
    </div>
    }
</div>
<div class="preview-container">
    <loading-component *ngIf="loadingSpinner$ | async"></loading-component>
    @if (!(loadingSpinner$ | async)) {
    <div class="bg-preview">
        @switch (data.type) {
        @case ('image') {
        <div class="bg-white rounded-lg flex flex-col items-center justify-center mt-[15px] p-2 max-w-96">
            <img class="border border-[#bdbdbd] rounded-lg mt-[15px] p-2" [src]="data.midia" alt="Preview" />
            @if (data.midia && this.mode === 'edit' && !isEdit) {
            <div class="w-full flex gap-2 items-center justify-center mt-[15px] p-2 border border-[#bdbdbd] rounded-lg">
                <strong class="w-[80%]">{{ data.message }}</strong><mat-icon
                    class="hover:bg-[#f2f2f3] hover:fill-white hover:rounded-full cursor-pointer w-[3rem] text-black"
                    (click)="toggleEdit()">edit</mat-icon>
            </div>
            }
            @if (data.midia && isEdit) {
            <div class="w-full flex gap-2 items-center justify-between mt-[15px] p-2">
                <input [(ngModel)]="data.message" class="input-field">
                <mat-icon class="hover:bg-[#f2f2f3] hover:fill-white hover:rounded-full text-red-500 cursor-pointer"
                    (click)="toggleEdit()">clear</mat-icon>
            </div>
            }
        </div>
        }
        @case ('video') {
        <div class="bg-white rounded-lg flex flex-col items-center justify-center mt-[15px] p-2 max-w-96">
            <video controls [src]="data.midia"></video>
            @if (data.midia && this.mode === 'edit' && !isEdit) {
            <div class="w-full flex gap-2 items-center justify-center mt-[15px] p-2 border border-[#bdbdbd] rounded-lg">
                <strong>{{ data.message }}</strong><mat-icon
                    class="hover:bg-[#f2f2f3] hover:fill-white hover:rounded-full cursor-pointer w-[3rem]  text-black"
                    (click)="toggleEdit()">edit</mat-icon>
            </div>
            }
            @if (data.midia && isEdit) {
            <div class="w-full flex gap-2 items-center justify-center mt-[15px] p-2">
                <input type="text" [(ngModel)]="data.message" class="input-field">
                <mat-icon class="hover:bg-[#f2f2f3] hover:fill-white hover:rounded-full text-red-500 cursor-pointer"
                    (click)="toggleEdit()">clear</mat-icon>
            </div>
            }
        </div>
        }
        @case ('audio') {
        <div class="bg-white rounded-lg flex flex-col items-center justify-center mt-[15px] p-2 max-w-96">
            <audio controls [src]="data.midia"></audio>
            @if (data.midia && this.mode === 'edit' && !isEdit) {
            <div class="w-full flex gap-2 items-center justify-center mt-[15px] p-2 border border-[#bdbdbd] rounded-lg">
                <strong>{{ data.message }}</strong><mat-icon
                    class="hover:bg-[#f2f2f3] hover:fill-white hover:rounded-full cursor-pointer w-[3rem] text-black"
                    (click)="toggleEdit()">edit</mat-icon>
            </div>
            }
            @if (data.midia && isEdit) {
            <div class="w-full flex gap-2 items-center justify-center mt-[15px] p-2 max-w-96">
                <input type="text" [(ngModel)]="data.message" class="input-field">
                <mat-icon class="hover:bg-[#f2f2f3] hover:fill-white hover:rounded-full text-red-500 cursor-pointer"
                    (click)="toggleEdit()">clear</mat-icon>
            </div>
            }
        </div>
        }
        @case ('document') {
        <div class="bg-white rounded-lg flex flex-col items-center justify-center mt-[15px] p-2 max-w-96">
            <a class="flex items-center justify-center gap-2" [href]="data.midia" target="_blank" download>Download {{ data.filename ??
                getFileNameFromUrl(data.midia) | truncate: 100
                }} <mat-icon>file_download</mat-icon></a>
            @if (data.midia && this.mode === 'edit' && !isEdit) {
            <div class="w-full flex gap-2 items-center justify-center mt-[15px] p-2 border border-[#bdbdbd] rounded-lg">
                <strong>{{ data.message }}</strong> <mat-icon
                    class="hover:bg-[#f2f2f3] hover:fill-white hover:rounded-full cursor-pointer w-[3rem]  text-black"
                    (click)="toggleEdit()">edit</mat-icon>
            </div>
            }
            @if (data.midia && isEdit) {
            <div class="w-full flex gap-2 items-center justify-center mt-[15px] p-2">
                <input type="text" [(ngModel)]="data.message" class="input-field">
                <mat-icon class="hover:bg-[#f2f2f3] hover:fill-white hover:rounded-full text-red-500 cursor-pointer"
                    (click)="toggleEdit()">clear</mat-icon>
            </div>
            }
        </div>
        }
        }
    </div>
    }
    @if (!(loadingSpinner$ | async) && mode !== 'view') {
    <input class="input-field mt-4 mb-2" [(ngModel)]="data.message" *ngIf="data.type !== 'audio' && this.mode !== 'edit'" type="text" name="message" [placeholder]="'Adicione uma legenda para ' + translateType(data.type)" />    

    <div class="flex flex-1 justify-center items-center w-full gap-4 mt-[15px]">
        <button (click)="onCancel()"
            class="h-[45px] w-[50%] px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100">Cancelar</button>
        <button (click)="onSend()"
            class="h-[45px] w-[50%] px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none">Enviar</button>
    </div>
    }
</div>