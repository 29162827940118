<div class="w-full">
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
  </svg>

  <h1 class="font-black text-[20px] text-black mt-2.5">Selecinar localização</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Pesquisa o endereço ou clique no mapa para selecionar o ponto</h6>

  <div class="mb-2">
    <input placeholder="Digite um endereço..." class="input-field w-full" type="text" [matAutocomplete]="auto"
      [formControl]="addressControl" />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAddress"
      (optionSelected)="onOptionSelected($event)">
      <mat-option *ngFor="let suggestion of filteredSuggestions$ | async" [value]="suggestion">
        {{ suggestion.formattedAddress }}
      </mat-option>
    </mat-autocomplete>
  </div>
  <google-map height="400px" width="750px" [center]="center" [zoom]="zoom" (mapClick)="addMarker($event)"
    (mapMousemove)="move($event)">
    @for (position of markerPositions; track position) {
    <map-marker [position]="position" [options]="markerOptions" />
    }
  </google-map>
</div>
<div class="flex justify-between items-center w-full gap-2 px-0 mt-4">
  <button
    class="h-[45px] flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[50%]"
    (click)="close()">Cancelar</button>
  <button
    class="h-[45px] px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[50%]"
    (click)="send()">
    Enviar
  </button>
</div>