<div style="color: red !important;" *ngIf="isFieldInvalidAndDirtyAndTouched()">
  <div *ngIf="isFieldErrorsRequired()">
    <span class="form-text small text-red-600">{{label}} é obrigatório(a).</span>
  </div>
  <div *ngIf="isFieldErrorsMin()">
    <span class="form-text small text-red-600">{{label}} está com poucos caracteres.</span>
  </div>
  <div *ngIf="isFieldErrorsMax()">
    <span class="form-text small text-red-600">{{label}} está com o número de caracteres excedido.</span>
  </div>
  <div *ngIf="isFieldErrorsPattern()">
    <span class="form-text small text-red-600">{{label}} está com o padrão inválido.</span>
  </div>
  <div *ngIf="isFieldErrorsPreviousDate()">
    <span class="form-text small text-red-600">{{label}} retroativa não é permitida.</span>
  </div>
  <div *ngIf="isFieldErrorsFutureDate()">
    <span class="form-text small text-red-600">{{label}} futura não é permitida.</span>
  </div>
  <div *ngIf="isFieldErrors18YearOldDate()">
    <span class="form-text small text-red-600">{{label}} é uma data que não atinge a maioridade.</span>
  </div>
  <div *ngIf="isFieldErrorsEmail()">
    <span class="form-text small text-red-600">{{label}} está inválido.</span>
  </div>
  <div *ngIf="isFieldErrorsCpf()">
    <span class="form-text small text-red-600">{{label}} está inválido.</span>
  </div>
  <div *ngIf="isFieldErrorsRequest()">
    <span class="form-text small text-red-600">{{label}} está no formato errado.</span>
  </div>
</div>