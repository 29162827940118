import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Integration } from '../models/integration.model';

@Injectable({ providedIn: 'root' })
export class IntegrationService extends AbstractService<Integration> {

  getURLBase(): string {
    return '/integration';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Integration): void {
    delete obj?._id;
  }
  preUpdate(obj: Integration): void {
  }

}  