import { Component, AfterViewInit, Output, EventEmitter, ViewEncapsulation, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Message } from '../../../models/message.model';

@Component({
  selector: 'app-google-maps-view',
  templateUrl: './google-maps-view.component.html',
  styleUrls: ['./google-maps-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GoogleMapsViewComponent implements AfterViewInit {
  
  center: google.maps.LatLngLiteral = {lat: -15.83897, lng: -48.027201};
  zoom = 15;
  display: google.maps.LatLngLiteral;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [{lat: -15.83897, lng: -48.027201}];

  constructor(
    public dialogRef: MatDialogRef<GoogleMapsViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Message,
  ) { 
    if (data && data.metadata.place) {
      this.center = {lat: data.metadata.place.location.latitude, lng: data.metadata.place.location.longitude};
      this.markerPositions = [{lat: data.metadata.place.location.latitude, lng: data.metadata.place.location.longitude}];
    }
  }

  ngAfterViewInit(): void {
  }

  moveMap(event: google.maps.MapMouseEvent) {
    this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    this.display = event.latLng.toJSON();
  }

  displayAddress(suggestion: any): string {
    return suggestion ? suggestion.formattedAddress : '';
  }

  close(): void {
    this.dialogRef.close();
  }

}