import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Observable } from 'rxjs';
import { Note } from '../models/note.model';

@Injectable({ providedIn: 'root' })
export class NoteService extends AbstractService<Note> {

  getURLBase(): string {
    return '/note';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Note): void {
    delete obj?._id;
  }

  preUpdate(obj: Note): void {
  }

  getByAttendanceAndContact(attendanceId: string, contactId: string): Observable<Note[]> {
    return super.get(`/byAttendanceAndContact/${attendanceId}/${contactId}`);
  }

}
