import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Tag } from '../models/tag.model';

@Injectable({ providedIn: 'root' })
export class TagService extends AbstractService<Tag> {

  getURLBase(): string {
    return '/tag';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Tag): void {
    delete obj?._id;
  }

  preUpdate(obj: Tag): void {
  }

  override getList(title?: string): Observable<Tag[]> {
    const params = title ? { params: { title } } : {};
    return this.http.get<Tag[]>(this.getURLBase() + '/list', params);
  }
}
