import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Flow, FlowExecution } from '../models/flow.model';

@Injectable({ providedIn: 'root' })
export class FlowService extends AbstractService<Flow> {

  getURLBase(): string {
    return '/flow';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Flow): void {
    delete obj?._id;
  }

  preUpdate(obj: Flow): void {
  }

  triggerFlow(idFlow: string, idChannel: string, idContact: string): Observable<FlowExecution> {
    return super.put(`/trigger-flow/${idFlow}`, { idChannel, idContact });
  }

}
