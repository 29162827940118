import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Message } from '../models/message.model';

@Injectable({ providedIn: 'root' })
export class MessageService extends AbstractService<Message> {

  getURLBase(): string {
    return '/message';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  override preCreate(obj: Message): void {
    delete obj?._id;
  }
  override preUpdate(obj: Message): void {
  }

  getByAttendance(attendance: string): Observable<Message[]> {
    return super.get(`/byAttendance/${attendance}`);
  }

  getByMessageId(messageId: string): Observable<Message> {
    return super.get(`/messageId/${messageId}`);
  }

  reactMessage(obj: Message, reaction: string, idMessageToUpdate: string): Observable<void> {
    const body = {
      message: obj,
      reaction,
      idMessageToUpdate
    };
    return super.post('/reaction', body);
  }

  editMessage(idMessage: string, newContent: string): Observable<void> {
    return super.post(`/edit/${idMessage}`, { newContent });
  }

}
