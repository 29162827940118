export enum MessageTypeEnum {
  audio = "audio",
  contacts = "contacts",
  document = "document",
  image = "image",
  reaction = "reaction",
  interactive = "interactive",
  location = "location",
  text = "text",
  sticker = "sticker",
  template = "template",
  video = "video",
  button = "button",
}
