import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Channel } from '../models/channel.model';
import { Contact } from '../models/contact.model';
import { ImportContacts } from '../models/import-contacts.model';
import { ViaCepRes } from '../models/viacep.model';

@Injectable({ providedIn: 'root' })
export class ContactService extends AbstractService<Contact> {

  getURLBase(): string {
    return '/contact';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Contact): void {
    delete obj?._id;
  }

  preUpdate(obj: Contact): void {
  }

  updateTags(obj: Contact): Observable<Contact> {
    return super.put(`/tags/${obj._id}`, obj);
  }

  searchCep(cep: string): Observable<ViaCepRes> {
    return this.http.get<ViaCepRes>(`/integrations/viacep/${cep}`)
  }

  count(ids?: string[]): Promise<any> {
    let params = {};
    if (ids) {
      params['id'] = ids;
    }
    return lastValueFrom(this.http.get<number>(`/contact/tags/count/`, { params }));
  }

  countContactGroup(ids?: string[]): Promise<any> {
    let params = {};
    if (ids) {
      params['id'] = ids;
    }
    return lastValueFrom(this.http.get<number>(`/contact/contactGroup/count/`, { params }));
  }

  importContacts(element: ImportContacts): Observable<any> {
    return this.http.post<any>(`/contact/import`, element);
  }

  getByBirthday(page: number, perPage: number): Observable<Contact[]> {
    return super.get(`/birthday?page=${page}&perPage=${perPage}`);
  }

  getFieldsCustom(idContact: string): Observable<Array<any>> {
    return super.get(`/custom/${idContact}`);
  }

  getTotalizerContact(): Observable<Array<{ count: number, validStatus: string }>> {
    return super.get(`/totalizer`);
  }

  blockContact(contact: Contact, channel: Channel): Observable<void> {
    return super.post('/block', { contact, channel });
  }

  unblockContact(contact: Contact, channel: Channel): Observable<void> {
    return super.post('/unblock', { contact, channel });
  }
}
