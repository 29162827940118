import { DatePipe } from "@angular/common";
import { Buffer } from "buffer";
import { Company } from "../models/company.model";
import { Contact } from "../models/contact.model";
import { CountryModel } from "../models/country.model";
import { Traduct } from "../models/traduct.model";
import { User } from "../models/user.model";

export class UtilHelper {

  private static instance: UtilHelper;

  public static no_image = 'https://firebasestorage.googleapis.com/v0/b/infinite7-70f9d.appspot.com/o/blank.png?alt=media&token=fea2ea97-edbd-4589-a52e-e4b8d786be0b';
  public static on_file = 'https://w7.pngwing.com/pngs/521/255/png-transparent-computer-icons-data-file-document-file-format-others-thumbnail.png';
  public static default_image = 'https://static.thenounproject.com/png/1077596-200.png';
  public static on_pdf = 'https://cdn-icons-png.flaticon.com/512/80/80942.png';
  public static on_audio = 'https://cdn-icons-png.flaticon.com/512/709/709559.png';
  public static on_video = 'https://cdn-icons-png.flaticon.com/512/1179/1179120.png';
  public static no_image_upload = 'https://firebasestorage.googleapis.com/v0/b/zapteck-2707e.appspot.com/o/chatbot%2FFeatured%20icon.png_1723483385408?alt=media&token=e92cba10-34cb-4aaa-9589-2e6dff8bd5cc';
  public static default_image_sticker = 'https://firebasestorage.googleapis.com/v0/b/zapteck-2707e.appspot.com/o/messages%2Fsticker-icon-512x512-rzveai8i.png_1733943999986?alt=media&token=bfe1758c-00ea-451f-90d6-17e66f56bb90'

  public static get getInstance() {
    if (UtilHelper.instance) {
      return UtilHelper.instance;
    }
    return this.instance;
  };

  public static arrayBufferToBase64(buffer: any) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let byteLength = bytes.byteLength;

    for (let i = 0; i < byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  public static getUserImage(user: User): string {
    let image = this.no_image;
    if (user && user.image) {
      return user.image;
    }
    return image;
  }

  public static getContactImage(contact: Contact): string {
    let image = this.no_image;
    if (contact && contact.image) {
      return contact.image;
    }
    return image;
  }

  public static onImageError(event: Event): void {
    const target = event.target as HTMLImageElement;
    target.src = this.no_image;
  }

  public static onStickerError(event: Event): void {
    const target = event.target as HTMLImageElement;
    target.src = this.default_image_sticker;
  }

  public static getCompanyImage(company: Company): string {
    let image = this.no_image;
    if (company && company.image) {
      return company.image;
    }
    return image;
  }

  public static generateTokenTowardsApps(idUser: string, idCompany: string): string {
    const json = { idUser, idCompany };
    return Buffer.from(JSON.stringify(json), 'binary').toString('base64');
  }

  public static getGeneratedTokenTowardsApps(token: string): any {
    const decode = Buffer.from(token, 'base64').toString('binary');
    return JSON.parse(decode);
  }

  public static getContactAddress(contact: Contact): string {
    let address = '';
    if (contact && contact.address) {
      address = `${contact.address}, ${contact.city} - ${contact.state}`;
    }
    return address;
  }

  public static getEmojiTranslator(): Traduct {
    return <Traduct>{
      search: 'Buscar',
      clear: 'Limpar',
      notfound: 'Nenhum emoji encontrado',
      skintext: 'Escolha a cor padrão',
      categories: {
        search: 'Resultados da busca',
        recent: 'Usados frequentemente',
        smileys: 'Smileys & Pessoas',
        people: 'Pessoas & Corpo',
        nature: 'Animais & Natureza',
        foods: 'Comida & Bebida',
        activity: 'Atividade',
        places: 'Viagens & Lugares',
        objects: 'Objetos',
        symbols: 'Símbolos',
        flags: 'Bandeiras',
      },
      categorieslabel: 'Categorias',
      skintones: {
        1: 'Padrão',
        2: 'Clara',
        3: 'Clara Média',
        4: 'Média',
        5: 'Escura Média',
        6: 'Escura'
      }
    }
  }

  public static getCountries(): Array<CountryModel> {
    return <Array<CountryModel>>[
      // @TODO: A
      { name: 'África do Sul', code: '+27', suffix: '0', flag: '🇿🇦', mask: '000 000 0000' },
      { name: 'Arábia Saudita', code: '+966', suffix: '0', flag: '🇸🇦', mask: '000 000 000' },
      { name: 'Alemanha', code: '+49', suffix: '0', flag: '🇩🇪', mask: '0000 00000000' },
      { name: 'Angola', code: '+244', suffix: '9', flag: '🇦🇴', mask: '000 000 000' },
      { name: 'Afeganistão', code: '+93', suffix: '0', flag: '🇦🇫', mask: '000 000 000' },
      { name: 'Albânia', code: '+355', suffix: '4', flag: '🇦🇱', mask: '000 000 0000' },
      { name: 'Argélia', code: '+213', suffix: '5', flag: '🇩🇿', mask: '000 000 000' },
      { name: 'Argentina', code: '+54', suffix: '9', flag: '🇦🇷', mask: '0000 000 000' },
      { name: 'Armênia', code: '+374', suffix: '0', flag: '🇦🇲', mask: '000 000 000' },
      { name: 'Austrália', code: '+61', suffix: '2', flag: '🇦🇺', mask: '000 000 000' },
      { name: 'Áustria', code: '+43', suffix: '1', flag: '🇦🇹', mask: '000 0000' },
      { name: 'Azerbaijão', code: '+994', suffix: '0', flag: '🇦🇿', mask: '00 000 0000' },
      // @TODO: B
      { name: 'Bahamas', code: '+1-242', suffix: '0', flag: '🇧🇸', mask: '(000) 000-0000' },
      { name: 'Bahrein', code: '+973', suffix: '0', flag: '🇧🇭', mask: '0000 0000' },
      { name: 'Bangladesh', code: '+880', suffix: '0', flag: '🇧🇩', mask: '000 000 0000' },
      { name: 'Barbados', code: '+1-246', suffix: '0', flag: '🇧🇧', mask: '(000) 000-0000' },
      { name: 'Bielorrússia', code: '+375', suffix: '0', flag: '🇧🇾', mask: '(00) 000-00-00' },
      { name: 'Bélgica', code: '+32', suffix: '2', flag: '🇧🇪', mask: '000 000 000' },
      { name: 'Belize', code: '+501', suffix: '0', flag: '🇧🇿', mask: '000-0000' },
      { name: 'Benim', code: '+229', suffix: '0', flag: '🇧🇯', mask: '000 000 000' },
      { name: 'Bhutão', code: '+975', suffix: '0', flag: '🇧🇹', mask: '000 000 00' },
      { name: 'Bolívia', code: '+591', suffix: '0', flag: '🇧🇴', mask: '000 000 000' },
      { name: 'Bósnia e Herzegovina', code: '+387', suffix: '0', flag: '🇧🇦', mask: '000 000 000' },
      { name: 'Botsuana', code: '+267', suffix: '0', flag: '🇧🇼', mask: '00 000 0000' },
      { name: 'Brasil', code: '+55', suffix: '5', flag: '🇧🇷', mask: '(00) 0000-0000||(00) 00000-0000' },
      { name: 'Brunei', code: '+673', suffix: '0', flag: '🇧🇳', mask: '000 0000' },
      { name: 'Bulgária', code: '+359', suffix: '0', flag: '🇧🇬', mask: '00 000 000' },
      { name: 'Burkina Faso', code: '+226', suffix: '0', flag: '🇧🇫', mask: '00 00 00 00' },
      { name: 'Burundi', code: '+257', suffix: '0', flag: '🇧🇮', mask: '000 000 000' },
      // @TODO: C
      { name: 'Cabo Verde', code: '+238', suffix: '9', flag: '🇨🇻', mask: '000 00 00' },
      { name: 'Congo', code: '+242', suffix: '6', flag: '🇨🇬', mask: '000 000 000' },
      { name: 'Congo (DRC)', code: '+243', suffix: '8', flag: '🇨🇩', mask: '000 000 000' },
      { name: 'Camboja', code: '+855', suffix: '0', flag: '🇰🇭', mask: '000 000 000' },
      { name: 'Camarões', code: '+237', suffix: '0', flag: '🇨🇲', mask: '000 000 000' },
      { name: 'Canadá', code: '+1', suffix: '0', flag: '🇨🇦', mask: '(000) 000-0000' },
      { name: 'Chade', code: '+235', suffix: '0', flag: '🇹🇩', mask: '000 000 000' },
      { name: 'Chile', code: '+56', suffix: '9', flag: '🇨🇱', mask: '0 0000 0000' },
      { name: 'China', code: '+86', suffix: '0', flag: '🇨🇳', mask: '000 0000 0000' },
      { name: 'Colômbia', code: '+57', suffix: '3', flag: '🇨🇴', mask: '(000) 000 0000' },
      { name: 'Comores', code: '+269', suffix: '0', flag: '🇰🇲', mask: '000 0000' },
      { name: 'Congo (Congo-Brazzaville)', code: '+242', suffix: '0', flag: '🇨🇬', mask: '000 000 000' },
      { name: 'Congo (República Democrática)', code: '+243', suffix: '0', flag: '🇨🇩', mask: '000 000 000' },
      { name: 'Costa Rica', code: '+506', suffix: '0', flag: '🇨🇷', mask: '0000 0000' },
      { name: 'Croácia', code: '+385', suffix: '0', flag: '🇭🇷', mask: '000 000 000' },
      { name: 'Cuba', code: '+53', suffix: '0', flag: '🇨🇺', mask: '000 000 000' },
      { name: 'Chipre', code: '+357', suffix: '0', flag: '🇨🇾', mask: '000 0000' },
      { name: 'Costa do Marfim', code: '+225', suffix: '7', flag: '🇨🇮', mask: '000 000 000' },
      { name: 'Cazaquistão', code: '+7', suffix: '0', flag: '🇰🇿', mask: '000 000 0000' },
      { name: 'Coréia do Norte', code: '+850', suffix: '0', flag: '🇰🇵', mask: '000-0000' },
      { name: 'Catar', code: '+974', suffix: '0', flag: '🇶🇦', mask: '0000 0000' },
      { name: 'Cingapura', code: '+65', suffix: '0', flag: '🇸🇬', mask: '0000 0000' },
      { name: 'Coréia do Sul', code: '+82', suffix: '0', flag: '🇰🇷', mask: '00 0000 0000' },
      { name: 'Cidade do Vaticano', code: '+379', suffix: '0', flag: '🇻🇦', mask: '000 000 000' },
      // @TODO: D
      { name: 'Dinamarca', code: '+45', suffix: '0', flag: '🇩🇰', mask: '00 00 00 00' },
      { name: 'Djibuti', code: '+253', suffix: '0', flag: '🇩🇯', mask: '00 00 00 00' },
      { name: 'Domínica', code: '+1-767', suffix: '0', flag: '🇩🇲', mask: '(000) 000-0000' },
      // @TODO: E
      { name: 'Equador', code: '+593', suffix: '0', flag: '🇪🇨', mask: '0 000 0000' },
      { name: 'Egito', code: '+20', suffix: '0', flag: '🇪🇬', mask: '00 000 0000' },
      { name: 'El Salvador', code: '+503', suffix: '0', flag: '🇸🇻', mask: '0000 0000' },
      { name: 'Eritreia', code: '+291', suffix: '0', flag: '🇪🇷', mask: '000 000 000' },
      { name: 'Estônia', code: '+372', suffix: '0', flag: '🇪🇪', mask: '000 000 000' },
      { name: 'Essuatíni', code: '+268', suffix: '0', flag: '🇸🇿', mask: '000 0000' },
      { name: 'Etiópia', code: '+251', suffix: '0', flag: '🇪🇹', mask: '000 000 000' },
      { name: 'Eslováquia', code: '+421', suffix: '0', flag: '🇸🇰', mask: '000 000 000' },
      { name: 'Eslovênia', code: '+386', suffix: '0', flag: '🇸🇮', mask: '00 000 000' },
      { name: 'Espanha', code: '+34', suffix: '0', flag: '🇪🇸', mask: '000 000 000' },
      { name: 'Emirados Árabes Unidos', code: '+971', suffix: '0', flag: '🇦🇪', mask: '000 000 000' },
      { name: 'Estados Unidos', code: '+1', suffix: '0', flag: '🇺🇸', mask: '(000) 000-0000' },
      // @TODO: F
      { name: 'Fiji', code: '+679', suffix: '0', flag: '🇫🇯', mask: '000 0000' },
      { name: 'Finlândia', code: '+358', suffix: '0', flag: '🇫🇮', mask: '000 000 000' },
      { name: 'França', code: '+33', suffix: '0', flag: '🇫🇷', mask: '0 00 00 00 00' },
      { name: 'Filipinas', code: '+63', suffix: '0', flag: '🇵🇭', mask: '0000 0000' },
      // @TODO: G
      { name: 'Gabão', code: '+241', suffix: '0', flag: '🇬🇦', mask: '00 00 00 00' },
      { name: 'Gâmbia', code: '+220', suffix: '0', flag: '🇬🇲', mask: '000 0000' },
      { name: 'Geórgia', code: '+995', suffix: '0', flag: '🇬🇪', mask: '000 000 000' },
      { name: 'Gana', code: '+233', suffix: '0', flag: '🇬🇭', mask: '000 000 000' },
      { name: 'Grécia', code: '+30', suffix: '0', flag: '🇬🇷', mask: '00 000 0000' },
      { name: 'Granada', code: '+1-473', suffix: '0', flag: '🇬🇩', mask: '(000) 000-0000' },
      { name: 'Guatemala', code: '+502', suffix: '0', flag: '🇵🇪', mask: '0000 0000' },
      { name: 'Guiné', code: '+224', suffix: '0', flag: '🇲🇱', mask: '000 000 000' },
      { name: 'Guiné-Bissau', code: '+245', suffix: '0', flag: '🇬🇼', mask: '000 000 000' },
      { name: 'Guiana', code: '+592', suffix: '0', flag: '🇬🇾', mask: '000 0000' },
      { name: 'Guiné Equatorial', code: '+240', suffix: '0', flag: '🇬🇶', mask: '000 000 000' },
      // @TODO: H
      { name: 'Haiti', code: '+509', suffix: '0', flag: '🇭🇹', mask: '0000 0000' },
      { name: 'Honduras', code: '+504', suffix: '0', flag: '🇭🇳', mask: '0000 0000' },
      { name: 'Hungria', code: '+36', suffix: '0', flag: '🇭🇺', mask: '00 000 0000' },
      { name: 'Holanda', code: '+31', suffix: '0', flag: '🇳🇱', mask: '00 000 0000' },
      // @TODO: I
      { name: 'Islândia', code: '+354', suffix: '0', flag: '🇮🇸', mask: '000 0000' },
      { name: 'Índia', code: '+91', suffix: '0', flag: '🇮🇳', mask: '000 000 0000' },
      { name: 'Indonésia', code: '+62', suffix: '0', flag: '🇮🇩', mask: '000 000 0000' },
      { name: 'Irã', code: '+98', suffix: '0', flag: '🇮🇷', mask: '000 0000 0000' },
      { name: 'Iraque', code: '+964', suffix: '0', flag: '🇮🇶', mask: '000 000 000' },
      { name: 'Irlanda', code: '+353', suffix: '0', flag: '🇮🇪', mask: '00 000 0000' },
      { name: 'Israel', code: '+972', suffix: '0', flag: '🇮🇱', mask: '000-000-000' },
      { name: 'Itália', code: '+39', suffix: '0', flag: '🇮🇹', mask: '000 000 0000' },
      { name: 'Ilhas Marshall', code: '+692', suffix: '0', flag: '🇲🇭', mask: '000-0000' },
      { name: 'Ilhas Salomão', code: '+677', suffix: '0', flag: '🇸🇧', mask: '00000' },
      { name: 'Iémen', code: '+967', suffix: '0', flag: '🇾🇪', mask: '000 000 000' },
      // @TODO: J
      { name: 'Jamaica', code: '+1-876', suffix: '0', flag: '🇯🇲', mask: '(000) 000-0000' },
      { name: 'Japão', code: '+81', suffix: '0', flag: '🇯🇵', mask: '000-0000-0000' },
      { name: 'Jordânia', code: '+962', suffix: '0', flag: '🇯🇴', mask: '000 0000 000' },
      // @TODO: K
      { name: 'Kuwait', code: '+965', suffix: '0', flag: '🇰🇼', mask: '0000 0000' },
      // @TODO: L
      { name: 'Laos', code: '+856', suffix: '0', flag: '🇱🇦', mask: '000 000 000' },
      { name: 'Letônia', code: '+371', suffix: '0', flag: '🇱🇻', mask: '000 0000' },
      { name: 'Líbano', code: '+961', suffix: '0', flag: '🇱🇧', mask: '00 000 000' },
      { name: 'Lesoto', code: '+266', suffix: '0', flag: '🇱🇸', mask: '000 000 000' },
      { name: 'Libéria', code: '+231', suffix: '0', flag: '🇱🇸', mask: '000 0000' },
      { name: 'Líbia', code: '+218', suffix: '0', flag: '🇱🇾', mask: '000 0000 000' },
      { name: 'Liechtenstein', code: '+423', suffix: '0', flag: '🇱🇮', mask: '000 000 000' },
      { name: 'Lituânia', code: '+370', suffix: '0', flag: '🇱🇹', mask: '000 0000' },
      { name: 'Luxemburgo', code: '+352', suffix: '0', flag: '🇱🇺', mask: '000 000 000' },
      // @TODO: M
      { name: 'Madagáscar', code: '+261', suffix: '0', flag: '🇲🇬', mask: '00 00 00 00' },
      { name: 'Maláui', code: '+265', suffix: '0', flag: '🇲🇼', mask: '000 000 000' },
      { name: 'Malásia', code: '+60', suffix: '0', flag: '🇲🇾', mask: '000 0000 0000' },
      { name: 'Maldivas', code: '+960', suffix: '0', flag: '🇲🇻', mask: '000 0000' },
      { name: 'Mali', code: '+223', suffix: '0', flag: '🇲🇱', mask: '00 00 00 00' },
      { name: 'Malta', code: '+356', suffix: '0', flag: '🇲🇹', mask: '0000 0000' },
      { name: 'Mauritânia', code: '+222', suffix: '0', flag: '🇲🇷', mask: '00 00 00 00' },
      { name: 'Maurícia', code: '+230', suffix: '0', flag: '🇲🇺', mask: '000 0000' },
      { name: 'México', code: '+52', suffix: '1', flag: '🇲🇽', mask: '(00) 0000-0000' },
      { name: 'Micronésia', code: '+691', suffix: '0', flag: '🇫🇲', mask: '000 0000' },
      { name: 'Moldávia', code: '+373', suffix: '0', flag: '🇲🇩', mask: '000 000 000' },
      { name: 'Mônaco', code: '+377', suffix: '0', flag: '🇲🇨', mask: '00 00 00 00' },
      { name: 'Mongólia', code: '+976', suffix: '0', flag: '🇲🇳', mask: '00 000 000' },
      { name: 'Montenegro', code: '+382', suffix: '0', flag: '🇲🇪', mask: '00 000 000' },
      { name: 'Marrocos', code: '+212', suffix: '0', flag: '🇲🇦', mask: '00 00 00 00' },
      { name: 'Moçambique', code: '+258', suffix: '0', flag: '🇲🇿', mask: '000 000 000' },
      { name: 'Mianmar', code: '+95', suffix: '0', flag: '🇲🇲', mask: '00 000 000' },
      { name: 'Macedônia do Norte', code: '+389', suffix: '0', flag: '🇲🇰', mask: '00 000 000' },
      // @TODO: N
      { name: 'Namíbia', code: '+264', suffix: '0', flag: '🇳🇦', mask: '000 000 000' },
      { name: 'Nauru', code: '+674', suffix: '0', flag: '🇳🇷', mask: '000 0000' },
      { name: 'Nepal', code: '+977', suffix: '0', flag: '🇳🇵', mask: '000 000 0000' },
      { name: 'Nova Zelândia', code: '+64', suffix: '0', flag: '🇳🇿', mask: '000 0000' },
      { name: 'Nicarágua', code: '+505', suffix: '0', flag: '🇳🇮', mask: '000 0000' },
      { name: 'Níger', code: '+227', suffix: '0', flag: '🇳🇪', mask: '00 00 00 00' },
      { name: 'Nigéria', code: '+234', suffix: '0', flag: '🇳🇬', mask: '000 000 0000' },
      { name: 'Noruega', code: '+47', suffix: '0', flag: '🇳🇴', mask: '000 00 000' },
      // @TODO: O
      { name: 'Omã', code: '+968', suffix: '0', flag: '🇴🇲', mask: '000 0000' },
      // @TODO: P
      { name: 'Paquistão', code: '+92', suffix: '0', flag: '🇵🇰', mask: '000 0000 0000' },
      { name: 'Palau', code: '+680', suffix: '0', flag: '🇵🇼', mask: '000-0000' },
      { name: 'Panamá', code: '+507', suffix: '0', flag: '🇵🇦', mask: '000 0000' },
      { name: 'Papua Nova Guiné', code: '+675', suffix: '0', flag: '🇵🇬', mask: '000 0000' },
      { name: 'Paraguai', code: '+595', suffix: '0', flag: '🇵🇾', mask: '000 000 000' },
      { name: 'Peru', code: '+51', suffix: '0', flag: '🇵🇪', mask: '000 000 000' },
      { name: 'Polônia', code: '+48', suffix: '0', flag: '🇵🇱', mask: '000 000 000' },
      { name: 'Portugal', code: '+351', suffix: '0', flag: '🇵🇹', mask: '000 000 000' },
      // @TODO: Q
      { name: 'Quênia', code: '+254', suffix: '0', flag: '🇰🇪', mask: '000 000 000' },
      { name: 'Quiribáti', code: '+686', suffix: '0', flag: '🇰🇮', mask: '00000' },
      { name: 'Quirguistão', code: '+996', suffix: '0', flag: '🇰🇬', mask: '000 000 000' },
      // @TODO: R
      { name: 'Romênia', code: '+40', suffix: '0', flag: '🇷🇴', mask: '000 000 000' },
      { name: 'Rússia', code: '+7', suffix: '0', flag: '🇷🇺', mask: '000 000 0000' },
      { name: 'Ruanda', code: '+250', suffix: '0', flag: '🇷🇼', mask: '000 000 000' },
      { name: 'República Centro-Africana', code: '+236', suffix: '0', flag: '🇨🇫', mask: '000 000 000' },
      { name: 'República Dominicana', code: '+1-809', suffix: '0', flag: '🇩🇴', mask: '(000) 000-0000' },
      { name: 'Reino Unido', code: '+44', suffix: '0', flag: '🇬🇧', mask: '00000 000000' },
      // @TODO: S
      { name: 'São Cristóvão e Nevis', code: '+1-869', suffix: '0', flag: '🇰🇳', mask: '(000) 000-0000' },
      { name: 'Santa Lúcia', code: '+1-758', suffix: '0', flag: '🇱🇨', mask: '(000) 000-0000' },
      { name: 'São Vicente e Granadinas', code: '+1-784', suffix: '0', flag: '🇻🇨', mask: '(000) 000-0000' },
      { name: 'Samoa', code: '+685', suffix: '0', flag: '🇼🇸', mask: '00000' },
      { name: 'São Marino', code: '+378', suffix: '0', flag: '🇸🇲', mask: '000 000 0000' },
      { name: 'São Tomé e Príncipe', code: '+239', suffix: '0', flag: '🇱🇸', mask: '000 0000' },
      { name: 'Senegal', code: '+221', suffix: '0', flag: '🇸🇳', mask: '00 00 00 00' },
      { name: 'Sérvia', code: '+381', suffix: '0', flag: '🇷🇸', mask: '00 000 000' },
      { name: 'Seicheles', code: '+248', suffix: '0', flag: '🇸🇨', mask: '000 0000' },
      { name: 'Serra Leoa', code: '+232', suffix: '0', flag: '🇸🇱', mask: '000 000 000' },
      { name: 'São Martinho', code: '+1-721', suffix: '0', flag: '🇸🇽', mask: '(000) 000-0000' },
      { name: 'Somália', code: '+252', suffix: '0', flag: '🇸🇴', mask: '000 000 000' },
      { name: 'Sudão do Sul', code: '+211', suffix: '0', flag: '🇸🇸', mask: '000 000 000' },
      { name: 'Sri Lanka', code: '+94', suffix: '0', flag: '🇱🇰', mask: '0 00 000 000' },
      { name: 'Sudão', code: '+249', suffix: '0', flag: '🇸🇩', mask: '000 000 000' },
      { name: 'Suriname', code: '+597', suffix: '0', flag: '🇸🇷', mask: '000-0000' },
      { name: 'Suécia', code: '+46', suffix: '0', flag: '🇸🇪', mask: '00 000 000' },
      { name: 'Suíça', code: '+41', suffix: '0', flag: '🇨🇭', mask: '00 000 0000' },
      { name: 'Síria', code: '+963', suffix: '0', flag: '🇸🇾', mask: '000 000 000' },
      // @TODO: T
      { name: 'Taiwan', code: '+886', suffix: '0', flag: '🇹🇼', mask: '0 000 0000' },
      { name: 'Tadjiquistão', code: '+992', suffix: '0', flag: '🇹🇯', mask: '000 000 000' },
      { name: 'Tanzânia', code: '+255', suffix: '0', flag: '🇹🇿', mask: '000 000 000' },
      { name: 'Tailândia', code: '+66', suffix: '0', flag: '🇹🇭', mask: '000 000 000' },
      { name: 'Timor-Leste', code: '+670', suffix: '0', flag: '🇹🇱', mask: '000 0000' },
      { name: 'Togo', code: '+228', suffix: '0', flag: '🇹🇬', mask: '00 00 00 00' },
      { name: 'Tonga', code: '+676', suffix: '0', flag: '🇹🇴', mask: '00000' },
      { name: 'Trinidad e Tobago', code: '+1-868', suffix: '0', flag: '🇹🇹', mask: '(000) 000-0000' },
      { name: 'Tunísia', code: '+216', suffix: '0', flag: '🇹🇳', mask: '00 000 000' },
      { name: 'Túrquia', code: '+90', suffix: '0', flag: '🇹🇷', mask: '000 000 0000' },
      { name: 'Turcomenistão', code: '+993', suffix: '0', flag: '🇹🇲', mask: '000 000 000' },
      { name: 'Tuvalu', code: '+688', suffix: '0', flag: '🇹🇻', mask: '0000' },
      { name: 'Tchéquia', code: '+420', suffix: '0', flag: '🇨🇿', mask: '000 000 000' },
      // @TODO: U
      { name: 'Uganda', code: '+256', suffix: '0', flag: '🇺🇬', mask: '000 000 000' },
      { name: 'Ucrânia', code: '+380', suffix: '0', flag: '🇺🇦', mask: '000 000 000' },
      { name: 'Uruguai', code: '+598', suffix: '0', flag: '🇺🇾', mask: '000 000 000' },
      { name: 'Uzbequistão', code: '+998', suffix: '0', flag: '🇺🇿', mask: '00 000 0000' },
      // @TODO: V
      { name: 'Vanuatu', code: '+678', suffix: '0', flag: '🇻🇺', mask: '00000' },
      { name: 'Venezuela', code: '+58', suffix: '0', flag: '🇻🇪', mask: '0000 000000' },
      { name: 'Vietnã', code: '+84', suffix: '0', flag: '🇻🇳', mask: '000 000 000' },
      // @TODO: Z
      { name: 'Zâmbia', code: '+260', suffix: '0', flag: '🇿🇲', mask: '000 000 000' },
      { name: 'Zimbábue', code: '+263', suffix: '0', flag: '🇿🇼', mask: '000 000 000' }
    ];
  }

  public static formatExpireIn(date: Date): { text: string, isExpired: boolean } {
    const newDate = new Date(date);
    const datePipe = new DatePipe('pt-BR');
    const isExpired = newDate < new Date();
    const text = isExpired
      ? 'Expirou em ' + datePipe.transform(newDate, 'dd/MM') + ' às ' + datePipe.transform(newDate, 'HH:mm')
      : 'Expira em ' + datePipe.transform(newDate, 'dd/MM') + ' às ' + datePipe.transform(newDate, 'HH:mm');

    return { text, isExpired };
  }

  public static getCountryByPhoneNumber(phoneNumber: string): CountryModel | undefined {
    const countries = this.getCountries();

    for (let country of countries) {
      const countryCode = country.code.replace('+', '');

      if (phoneNumber.startsWith(countryCode)) {
        return country;
      }
    }
    return undefined;
  }

  public static getFileTypeLimit(fileType: string) {
    return this.FILE_TYPE_LIMITS[fileType];
  }

  public static getMimeType(fileType: string) {
    return this.FILE_MIME_TYPE[fileType];
  }


  private static readonly FILE_TYPE_LIMITS = {
    'image/jpeg': { maxSize: 5 * 1024 * 1024, alertTitle: 'Tamanho de imagem inválido', alertMessage: 'O tamanho do arquivo de imagem deve ser inferior a 5 MB.' },
    'image/png': { maxSize: 5 * 1024 * 1024, alertTitle: 'Tamanho de imagem inválido', alertMessage: 'O tamanho do arquivo de imagem deve ser inferior a 5 MB.' },
    'image/jpg': { maxSize: 5 * 1024 * 1024, alertTitle: 'Tamanho de imagem inválido', alertMessage: 'O tamanho do arquivo de imagem deve ser inferior a 5 MB.' },
    'video/mp4': { maxSize: 16 * 1024 * 1024, alertTitle: 'Tamanho de vídeo inválido', alertMessage: 'O tamanho do arquivo de vídeo deve ser inferior a 16 MB.' },
    'video/3gp': { maxSize: 16 * 1024 * 1024, alertTitle: 'Tamanho de vídeo inválido', alertMessage: 'O tamanho do arquivo de vídeo deve ser inferior a 16 MB.' },
    'text/plain': { maxSize: 100 * 1024 * 1024, alertTitle: 'Tamanho de documento inválido', alertMessage: 'O tamanho do arquivo de documento deve ser inferior a 100 MB.' },
    'application/vnd.ms-excel': { maxSize: 100 * 1024 * 1024, alertTitle: 'Tamanho de documento inválido', alertMessage: 'O tamanho do arquivo de documento deve ser inferior a 100 MB.' },
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': { maxSize: 100 * 1024 * 1024, alertTitle: 'Tamanho de documento inválido', alertMessage: 'O tamanho do arquivo de documento deve ser inferior a 100 MB.' },
    'application/msword': { maxSize: 100 * 1024 * 1024, alertTitle: 'Tamanho de documento inválido', alertMessage: 'O tamanho do arquivo de documento deve ser inferior a 100 MB.' },
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': { maxSize: 100 * 1024 * 1024, alertTitle: 'Tamanho de documento inválido', alertMessage: 'O tamanho do arquivo de documento deve ser inferior a 100 MB.' },
    'application/vnd.ms-powerpoint': { maxSize: 100 * 1024 * 1024, alertTitle: 'Tamanho de documento inválido', alertMessage: 'O tamanho do arquivo de documento deve ser inferior a 100 MB.' },
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': { maxSize: 100 * 1024 * 1024, alertTitle: 'Tamanho de documento inválido', alertMessage: 'O tamanho do arquivo de documento deve ser inferior a 100 MB.' },
    'application/pdf': { maxSize: 100 * 1024 * 1024, alertTitle: 'Tamanho de documento inválido', alertMessage: 'O tamanho do arquivo de documento deve ser inferior a 100 MB.' },
    'audio/acc': { maxSize: 16 * 1024 * 1024, alertTitle: 'Tamanho de áudio inválido', alertMessage: 'O tamanho do arquivo de áudio deve ser inferior a 16 MB.' },
    'audio/amr': { maxSize: 16 * 1024 * 1024, alertTitle: 'Tamanho de áudio inválido', alertMessage: 'O tamanho do arquivo de áudio deve ser inferior a 16 MB.' },
    'audio/mpeg': { maxSize: 16 * 1024 * 1024, alertTitle: 'Tamanho de áudio inválido', alertMessage: 'O tamanho do arquivo de áudio deve ser inferior a 16 MB.' },
    'audio/mp4': { maxSize: 16 * 1024 * 1024, alertTitle: 'Tamanho de áudio inválido', alertMessage: 'O tamanho do arquivo de áudio deve ser inferior a 16 MB.' },
    'audio/ogg': { maxSize: 16 * 1024 * 1024, alertTitle: 'Tamanho de áudio inválido', alertMessage: 'O tamanho do arquivo de áudio deve ser inferior a 16 MB.' },
  };

  private static readonly FILE_MIME_TYPE = {
    'jpeg': 'image',
    'png': 'image',
    'jpg': 'image',
    'mp4': 'video',
    '3gp': 'video',
    'txt': 'document',
    'xls': 'document',
    'xlsx': 'document',
    'doc': 'document',
    'docx': 'document',
    'ppt': 'document',
    'pptx': 'document',
    'pdf': 'document',
    'aac': 'audio',
    'amr': 'audio',
    'mpeg': 'audio',
    'mp3': 'audio',
    'ogg': 'audio',
  };

  public static formatWhatsappMessage(text: string): string {
    let formattedText = text.replace(/\n/g, '<br>');
    if (formattedText.startsWith('> _*')) {
      formattedText = formattedText.replace(/^> _\*(.*?)\*_/g, '<em><strong>$1</strong></em>');
    }

    formattedText = formattedText.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
    formattedText = formattedText.replace(/_(.*?)_/g, '<em>$1</em>');
    formattedText = formattedText.replace(/~(.*?)~/g, '<del>$1</del>');
    formattedText = formattedText.replace(/`(.*?)`/g, '<code>$1</code>');
    return formattedText;
  }

  public static getFileExtension(mimeType: string): string {
    const mimeTypes = {
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'image/gif': 'gif',
      'image/bmp': 'bmp',
      'image/webp': 'webp',
      'image/svg+xml': 'svg',
      'application/pdf': 'pdf',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'application/vnd.ms-powerpoint': 'ppt',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
      'text/plain': 'txt',
      'text/html': 'html',
      'text/csv': 'csv',
      'application/json': 'json',
      'application/zip': 'zip',
      'audio/mpeg': 'mp3',
      'audio/wav': 'wav',
      'video/mp4': 'mp4',
      'video/x-msvideo': 'avi',
      'video/mpeg': 'mpeg',
      'audio/ogg': 'ogg',
      'audio/ogg; codecs=opus': 'ogg',
    };
    if (mimeType) {
      return mimeTypes[mimeType] || 'unknown';
    } else {
      return 'Texto';
    }
  }

  public static getIconForContentType(contentType: string): string {
    const types: { [key: string]: string } = {
      'image/png': 'image',
      'image/jpeg': 'image',
      'image/gif': 'image',
      'video/mp4': 'videocam',
      'video/avi': 'videocam',
      'audio/mpeg': 'audiotrack',
      'audio/wav': 'audiotrack',
      'application/pdf': 'picture_as_pdf',
      'application/msword': 'description',
      'application/vnd.ms-excel': 'grid_on',
    };

    return types[contentType] ?? 'insert_drive_file';
  }

}
