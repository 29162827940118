import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewConversationService {
  private showModalSubject = new BehaviorSubject<boolean>(false);
  private contactIdSubject = new BehaviorSubject<string>(null);

  showModal$ = this.showModalSubject.asObservable();
  contactId$ = this.contactIdSubject.asObservable();

  openModal(contactId: string) {
    this.contactIdSubject.next(contactId);
    this.showModalSubject.next(true);
  }

  closeModal() {
    this.showModalSubject.next(false);
    this.contactIdSubject.next(null);
  }
}
