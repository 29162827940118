<div class="w-full">
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
  </svg>

  <h1 class="font-black text-[20px] text-black mt-2.5">Selecionar contato</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Selecione um contato para enviar</h6>

  <div class="container-page mt-[15px]">
    <div class="flex flex-1 flex-col">
      <div class="w-full flex items-center">
        <form class="flex w-full flex-col mb-4">
          <div class="flex w-full flex-col">
            <div class="flex gap-2">
              <div class="flex flex-1">
                <label for="simple-search" class="sr-only">Pesquisar</label>
                <div class="relative w-full">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8.52625 14.4874C7.34723 14.4874 6.19468 14.1378 5.21436 13.4828C4.23404 12.8278 3.46997 11.8967 3.01878 10.8075C2.56759 9.71819 2.44953 8.51958 2.67955 7.36321C2.90957 6.20684 3.47732 5.14465 4.31101 4.31095C5.14471 3.47726 6.2069 2.9095 7.36327 2.67949C8.51964 2.44947 9.71825 2.56752 10.8075 3.01872C11.8968 3.46991 12.8278 4.23398 13.4829 5.2143C14.1379 6.19462 14.4875 7.34717 14.4875 8.52619C14.4875 9.30904 14.3333 10.0842 14.0337 10.8075C13.7341 11.5307 13.295 12.1879 12.7415 12.7414C12.1879 13.295 11.5308 13.7341 10.8075 14.0337C10.0843 14.3333 9.3091 14.4874 8.52625 14.4874ZM8.52625 3.76036C7.58679 3.76036 6.66843 4.03894 5.8873 4.56088C5.10616 5.08282 4.49734 5.82467 4.13783 6.69261C3.77831 7.56056 3.68425 8.51563 3.86752 9.43704C4.0508 10.3584 4.5032 11.2048 5.1675 11.8691C5.8318 12.5334 6.67817 12.9858 7.59958 13.1691C8.52099 13.3524 9.47605 13.2583 10.344 12.8988C11.2119 12.5393 11.9538 11.9305 12.4757 11.1493C12.9977 10.3682 13.2763 9.44982 13.2763 8.51036C13.2763 7.25058 12.7758 6.0424 11.885 5.1516C10.9942 4.26081 9.78603 3.76036 8.52625 3.76036Z"
                        fill="#757575" />
                      <path
                        d="M15.8333 16.427C15.7553 16.4274 15.678 16.4122 15.606 16.3823C15.5339 16.3523 15.4686 16.3084 15.4137 16.2528L12.1442 12.9833C12.0393 12.8707 11.9822 12.7218 11.9849 12.568C11.9876 12.4142 12.0499 12.2674 12.1587 12.1586C12.2675 12.0499 12.4143 11.9875 12.5681 11.9848C12.7219 11.9821 12.8708 12.0392 12.9833 12.1441L16.2529 15.4137C16.3641 15.525 16.4266 15.6759 16.4266 15.8333C16.4266 15.9906 16.3641 16.1415 16.2529 16.2528C16.1981 16.3084 16.1327 16.3523 16.0607 16.3823C15.9886 16.4122 15.9113 16.4274 15.8333 16.427Z"
                        fill="#757575" />
                    </svg>
                  </div>
                  <input type="search" #searchBox (keyup)="search(searchBox.value) " class="input-field"
                    style="padding-left: 40px; box-sizing: border-box;" placeholder="Pesquisar..." />
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>

    <div id="table-modal-response" *ngIf="pager.total > 0">
      <table class="min-w-full divide-gray-100 divide-y-[3px]">
        <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Nome</th>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Email</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Telefone</th>
            <th scope="col" class="px-3 py-3 5 text-left text-sm font-light text-slate-500">Perfil</th>
            @if (!!data) {
            <th scope="col" class="px-3 py-3 5 text-left text-sm font-light text-slate-500">Bloquear/Desbloquear</th>
            }
            <th scope="col" class="relative py-3.5 pl-3 pr-4">
              <span class="sr-only"></span>
            </th>
          </tr>
        </thead>
        <tbody class="divide-gray-100 divide-y-[3px] bg-white">
          @for (item of pager.list; track $index) {
          <tr class="rounded-none">
            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">{{item.name | truncate: 50 }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm font-light text-gray-900">{{item.email ?? '-' | truncate:
              50}}</td>
            <td class="whitespace-nowrap px-3 py-4 text-sm font-light text-gray-900">{{item.phone}}</td>
            <td class="px3">
              <img class="w-8 h-8 rounded-full" [src]="getContactImage(item)" [alt]="item.name"
                (error)="onImageError($event)">
            </td>
            @if (!!data) {
            <td class="whitespace-nowrap px-3 py-4 text-sm font-light text-gray-900">
              {{ item?.blockedChannels?.includes(data.channel._id) ? 'Desbloquear' : 'Bloquear' }}
            </td>
            }
            <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
              <button class="text-[#4213F6]" (click)="select(item)">Selecionar</button>
            </td>
          </tr>
          }
        </tbody>
      </table>
      <paginator *ngIf="pager.total > pager.perPage" class="flex justify-content-center mt-3 mb-3" [pager]="pager"
        (onPager)="loadPage($event)">
      </paginator>
    </div>
    <empty-records-component *ngIf="pager.total == 0" [message]="'Você ainda não tem contatos adicionados'"
      [subMessage]="'Adicione um contato ao lado.'">
    </empty-records-component>
  </div>

</div>