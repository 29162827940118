import { Component, AfterViewInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, Observable, of, switchMap } from 'rxjs';
import { GooglePlaceType } from '../../../models/google-place.model';
import { GoolgePlaceService } from '../../../services/google-place.service';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GoogleMapsComponent implements AfterViewInit {
  
  addressControl = new FormControl('');
  filteredSuggestions$: Observable<any[]> = of([]);
  place: GooglePlaceType;

  center: google.maps.LatLngLiteral = {lat: -15.83897, lng: -48.027201};
  zoom = 15;
  display: google.maps.LatLngLiteral;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [{lat: -15.83897, lng: -48.027201}];

  constructor(
    public dialogRef: MatDialogRef<GoogleMapsComponent>,
    private goolgePlaceService: GoolgePlaceService
  ) { 
    this.filteredSuggestions$ = this.addressControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((value) => {
        if (!value) {
          return of([]);
        }
        return this.goolgePlaceService.getPlaces(value);
      })
    );
  }

  ngAfterViewInit(): void {
  }

  moveMap(event: google.maps.MapMouseEvent) {
    this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    this.display = event.latLng.toJSON();
  }

  addMarker(event: google.maps.MapMouseEvent) {
    this.markerPositions = [];
    this.markerPositions.push(event.latLng.toJSON());
    this.place = {
      formattedAddress: 'Localização Selecionada',
      location: {
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng()
      }
    }
  }

  displayAddress(suggestion: any): string {
    return suggestion ? suggestion.formattedAddress : '';
  }

  onOptionSelected(event: any): void {
    const selectedSuggestion = event.option.value;
    const { latitude, longitude } = selectedSuggestion.location;
    const latLng = {lat: latitude, lng: longitude};
    this.markerPositions = [];
    this.markerPositions.push(latLng);
    this.center = latLng;
    this.place = selectedSuggestion;
  }

  close(): void {
    this.dialogRef.close();
  }

  send(): void {
    this.dialogRef.close(this.place);
  }
}