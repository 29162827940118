import { Component, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { PreviewMediaComponent, PreviewMediaData, ResponseTypeEnum, UtilHelper } from '../../../../public-api';
import { AbstractComponent } from '../../../commons/abstract.component';
import { Pager } from '../../../commons/pager';
import { Category } from '../../../models/category.model';
import { Response } from '../../../models/response.model';
import { CategoryService } from '../../../services/category.service';
import { ResponseService } from '../../../services/response.service';

@Component({
  selector: 'modal-response-fast',
  templateUrl: './modal-response-fast.component.html',
  styleUrls: ['./modal-response-fast.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalResponseFastComponent extends AbstractComponent {
  listCategory: Category[] = [];

  pager: Pager<Response> = new Pager<Response>({ perPage: 7 });

  loadingSpinnerSubject = new BehaviorSubject<boolean>(true);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();

  isTableResponseApp: boolean;

  constructor(
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialogRef: MatDialogRef<ModalResponseFastComponent>,
    private categoryService: CategoryService,
    private responseService: ResponseService,
    private modalPreview: MatDialog,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getList(this.searchString);
    this.getListCategory();
  }

  search(term: string): void {
    this.getList(term);
  }

  selectedResponseFast(response: Response): void {
    if (response.midia) {
      const dialogRefPreview = this.modalPreview.open(PreviewMediaComponent, {
        width: '600px',
        data: <PreviewMediaData>{
          response,
          midia: response.midia,
          message: response.message,
          isTableResponseApp: true
        }
      });

      dialogRefPreview.afterClosed().subscribe((result) => {
        if (result) {
          this._matDialogRef.close(result);
        }
      });
    } else {
      this._matDialogRef.close(response);
    }
  }

  selectedCategory(category: string): void {
    this.getList(category);
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList(this.searchString);
  }

  getList(termSearch: string): void {
    this.loadingSpinnerSubject.next(true);

    this.searchParams = {
      company: this.getIDCurrentCompany(),
      type: ResponseTypeEnum.QUICK,
    };

    this.responseService.getAll(this.pager.page, this.pager.perPage, termSearch, this.searchParams).subscribe({
      next: (value: Pager<Response>) => {
        this.pager = value;
      },
      error: (err) => this.alertService.error('Ops! Ocorreu um erro ao tentar buscar as respostas rápidas. Tente novamente mais tarde.'),
      complete: () => this.loadingSpinnerSubject.next(false),
    });
  }

  private getListCategory(): void {
    this.categoryService.getList().subscribe({
      next: (value) => {
        this.listCategory = value;
      },
      error: err => this.alertService.error('Ops! Ocorreu um erro ao tentar buscar suas categorias. Tente novamente mais tarde.'),
    });
  }

  public getFileExtension(item: Response): string {
    if (item.vcardContact) {
      return 'Contato';
    }
    if (item.place) {
      return 'Localização';
    }
    return UtilHelper.getFileExtension(item.contentType);
  }

}