import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SharedModule } from '../../shared.module';
import { CardGraphicMediumComponent } from './card-graphic-medium/card-graphic-medium.component';
import { CardGraphicSmallComponent } from './card-graphic-small/card-graphic-small.component';
import { CardIconSocialComponent } from './card-icon-social/card-icon-social.component';
import { CardIconComponent } from './card-icon/card-icon.component';
import { CardPercentageChartComponent } from './card-percentage-chart/card-percentage-chart.component';
import { CardUsersComponent } from './card-users/card-users.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatIconModule,
    NgxChartsModule,
  ],
  declarations: [
    CardIconComponent,
    CardGraphicSmallComponent,
    CardGraphicMediumComponent,
    CardUsersComponent,
    CardIconSocialComponent,
    CardPercentageChartComponent
  ],
  exports: [
    CardIconComponent,
    CardGraphicSmallComponent,
    CardGraphicMediumComponent,
    CardUsersComponent,
    CardIconSocialComponent,
    CardPercentageChartComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class CardComponentModule { }
