import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Pager } from '../../../commons/pager';

@Component({
  selector: 'paginator',
  templateUrl: 'paginator.component.html'
})
export class PaginatorComponent implements OnInit {

  public disabled: boolean = false;
  public hidePageSize: boolean = false;
  public showFirstLastButtons: boolean = true;

  @Input() public pager: Pager<unknown>;

  @Output() public onPager: EventEmitter<number> = new EventEmitter<number>();

  constructor(private paginator: MatPaginatorIntl) { }

  ngOnInit() {
    this.setupPaginator();
  }

  setupPaginator() {
    this.paginator.itemsPerPageLabel = 'Itens por Página';
    this.paginator.nextPageLabel = 'Próxima Página';
    this.paginator.previousPageLabel = 'Página Anterior';
    this.paginator.firstPageLabel = 'Ir para Primeira Página';
    this.paginator.lastPageLabel = 'Ir para Última Página';
    this.paginator.getRangeLabel = this.getRangeDisplayText;
  }

  private getRangeDisplayText = (page: number, pageSize: number, length: number) => {
    const initialText = ``;  // customize this line
    if (length == 0 || pageSize == 0) {
      return `${initialText} 0 of ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;
    return `${initialText} ${startIndex + 1} - ${endIndex} de ${length}`; // customize this line
  };

  loadPage(event: PageEvent) {
    if (!!event) {
      const newPage = event.pageIndex + 1;
      this.pager.page = newPage;
      this.onPager.emit(newPage);
    }
  }

}
