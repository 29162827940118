<div class="w-full">

  <h1 class="font-black text-[20px] text-black mt-2.5">Visualizar localização</h1>

  <google-map height="400px" width="750px" [center]="center" [zoom]="zoom"
    (mapMousemove)="move($event)">
    @for (position of markerPositions; track position) {
    <map-marker [position]="position" [options]="markerOptions" />
    }
  </google-map>
</div>
<div class="flex justify-between items-center w-full gap-2 px-0 mt-4">
  <button
    class="h-[45px] flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[100%]"
    (click)="close()">Fechar</button>
</div>