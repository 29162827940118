<a href="javascript:void(0)" class="card-graphic-small-wrapper container-full">
  <mat-card class="">
    <mat-card-content class="container-full flex gap-4 items-center">
      <div class="card-graphic-small-content">
        <span class="card-total text-base">{{total}}</span>
        <span class="text-sm text-mutted mt-10 white-space">{{label}}</span>
      </div>
      <div>
        <div class="conatiner-graphic-small" *ngIf="graphicType && graphicType==='bar'">
          <ngx-charts-bar-vertical
            [view]="[250,280]"
            [results]="single"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [doughnut]="true"
            (select)="onSelect($event)">
          </ngx-charts-bar-vertical>
        </div>
        <div class="conatiner-graphic-small" *ngIf="graphicType && graphicType==='doughnut'">
          <ngx-charts-pie-chart
            [view]="[250,280]"
            [legend]="false"
            [labels]="false"
            [legendTitle]="''"
            [view]="[250,280]"
            [results]="single"
            [doughnut]="true">
          </ngx-charts-pie-chart>
        </div>
        <div *ngIf="graphicType && graphicType==='percentage'">
          <card-percentage-chart-component [percentage]="contactInfoChart" [radius]="99" [textColor]="textColor" [datasetTitle]="datasetTitle" />
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</a>
