<div class="w-full flex justify-center flex-col">
  <h1 class="font-black text-[20px] text-black">Configurações de Atendimento</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Selecione um canal para ver as configurações do seu atendimento</h6>
</div>

@if (channel) {
<div class="w-full overflow-hidden h-full">
  <form [formGroup]="formGroup" class="flex gap-4 w-full">
    <div class="flex w-[33%] mt-[15px]">
      <div class="mt-[15px] flex flex-col w-full gap-2">
        <div class="w-full border border-[#bdbdbd] rounded-lg flex flex-col p-2">
          <mat-slide-toggle color="primary" formControlName="limitAttendancePerAttendant"
            (change)="changeLimitAttendancePerAttendant()">
            <div class="flex flex-col items-start">
              <h1 class="text-base">Limitar quantidade de atendimentos por atendente</h1>
              <small class="text-zinc-400 font-light text-sm">
                Limitar quantidade de atendimentos que o atendente pode ter em sua fila
              </small>
            </div>
          </mat-slide-toggle>

          @if (formGroup.get('limitAttendancePerAttendant').value === true) {
          <div class="mt-4 flex w-full gap-2">
            <div class="flex flex-col w-full">
              <label class="font-medium text-black">Quantidade de atendimento por atendente</label>
              <input class="input-field" maxlength="3" formControlName="maxNumberAttendanceAttendant" placeholder=""
                [mask]="'0*'">
            </div>
          </div>
          }
        </div>
        <div class="flex flex-col gap-2 w-full">
          <div id="send-inactive" class="w-full border border-[#bdbdbd] rounded-lg flex flex-col p-2">
            <mat-slide-toggle color="primary" formControlName="sendResponseInactivy">
              <div class="flex flex-col items-start">
                <h1 class="text-base">Inatividade de contato</h1>
                <small class="text-zinc-400 font-light text-sm">
                  Mandar resposta automatica de inatividade para o contato
                </small>
              </div>
            </mat-slide-toggle>

            @if (formGroup.get('sendResponseInactivy').value === true) {
            <div id="quantity-period" class="mt-4 flex items-center w-full gap-4">
              <div id="label-period" class="flex flex-col w-full">
                <label class="font-medium text-black">Digite a quantidade</label>
                <input class="input-field" maxlength="2" formControlName="sendResponseAfterPeriod" [mask]="'0*'">
              </div>
              <div id="input-period" class="flex flex-col w-[50%]">
                <label class="font-medium text-black">Selecione o período</label>
                <mat-select class="input-field" [value]="selectedOptions"
                  (selectionChange)="changeSelectOptionsInactivy($event)">
                  <mat-option color="primary" [value]="'daySendResponse'">Dia</mat-option>
                  <mat-option color="primary" [value]="'hourSendResponse'">Hora</mat-option>
                  <mat-option color="primary" [value]="'minutesSendResponse'">Minuto</mat-option>
                </mat-select>
              </div>
            </div>
            }
          </div>
          <div id="send-inactive" class="w-full border border-[#bdbdbd] rounded-lg flex flex-col p-2">
            <mat-slide-toggle [checked]="isInactivityChatbot"
              (change)="changeIsAttendanceInactive('autoAttendanceInactivy')" color="primary"
              formControlName="autoAttendanceInactivy">
              <div class="flex flex-col items-start">
                <h1 class="text-base">Inatividade de auto atendimento (chatbot)</h1>
                <small class="text-zinc-400 font-light text-sm">
                  Mandar resposta automatica de inatividade de auto atendimento
                </small>
              </div>
            </mat-slide-toggle>
            @if (formGroup.get('autoAttendanceInactivy').value === true) {
            <div class="flex flex-col w-full mt-[15px]">
              <label class="font-medium text-black">Tempo em minutos</label>
              <input class="input-field" formControlName="autoAfterPeriod" [mask]="'0*'">
            </div>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="flex mt-[15px] w-[33%]">
      <div class="mt-[15px] flex flex-col w-full gap-2">
        <div class="w-full border border-[#bdbdbd] rounded-lg flex flex-col p-2">
          <mat-slide-toggle [checked]="!isInactivityChatbot" color="primary" formControlName="isAttendanceInactive"
            (change)="changeIsAttendanceInactive('isAttendanceInactive')">
            <div class="flex flex-col items-start">
              <h1 class="text-base">Transferência por inatividade (chatbot)</h1>
              <small class="text-zinc-400 font-light text-sm">
                Em caso de inatividade do cliente o atendimento será encaminhado automaticamente
              </small>
            </div>
          </mat-slide-toggle>

          @if (formGroup.get('isAttendanceInactive').value === true) {
          <div id="isAttendanceInactive" class="mt-4 flex w-full gap-2">
            <div id="minutes" class="flex flex-col w-full">
              <label class="flex items-center gap-2 font-medium text-black">
                Transferir após quantos minutos
              </label>
              <input class="input-field" maxlength="3" formControlName="minutesTransferAttendance" placeholder=""
                [mask]="'0*'">
            </div>

            <div id="transfer" class="flex flex-col w-full">
              <label class="font-medium text-black">Departamento</label>
              <mat-select class="input-field" formControlName="tranferTo" placeholder="Selecione">
                <div class="input-select-filter w-full p-2 mb-2">
                  <input class="input-field input-select-filter" type="text" (focus)="onFocusTransferTo()"
                    [formControl]="filterControlDepartmentToTransfer" placeholder="Pesquise por um departamento...">
                </div>
                @for (item of listDepartmentToTransfer; track item) {
                <mat-option [value]="item._id">{{item.name}}</mat-option>
                }
              </mat-select>
            </div>
          </div>
          }
        </div>
        <div id="closeAttendance" class="w-full border border-[#bdbdbd] rounded-lg flex flex-col p-2">
          <mat-slide-toggle color="primary" formControlName="closeAttendanceInactive"
            (change)="changeCloseAttendanceInactive()">
            <div class="flex flex-col items-start">
              <h1 class="text-base">Fechar por inatividade</h1>
              <small class="text-zinc-400 font-light text-sm">
                Em caso de inatividade do atendimento este será encerrado automaticamente
              </small>
            </div>
          </mat-slide-toggle>
          @if (formGroup.get('closeAttendanceInactive').value === true) {
          <div class="mt-4 flex w-full gap-2">
            <div id="minutes-close" class="flex flex-col w-[50%]">
              <label class="font-medium text-black">Finalizar a conversa após quantos minutos</label>
              <input class="input-field" formControlName="minutesFinishAttendance" placeholder="" [mask]="'0*'">
            </div>
          </div>
          }
        </div>
        <div class="border border-[#bdbdbd] rounded-lg p-2 flex items-center">
          <mat-slide-toggle color="primary"
            [checked]="formGroup.get('typeDistribution').value === 'LESS_ACTIVE_CONVERSATION'"
            (change)="onDistribuitionToggle($event)">
            <div class="flex flex-col items-start">
              <h1 class="text-base">Distribuição de Atendimento</h1>
              <small class="text-zinc-400 font-light text-sm">Atendente com Menos Conversas Ativas</small>
            </div>
          </mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="flex mt-[15px] w-[33%]">
      <div class="flex flex-col mt-[15px] gap-2 items-start justify-around w-full">
        <div class="w-full border border-[#bdbdbd] rounded-lg p-2 flex items-center">
          <mat-slide-toggle color="primary" [checked]="formGroup.get('typeDistribution').value === 'FIRST_ASSUME'"
            (change)="onListaToggle($event)">
            <div class="flex flex-col items-start">
              <h1 class="text-base">Lista de Atendimento</h1>
              <small class="text-zinc-400 font-light text-sm">Primeiro Atendente a Assumir</small>
            </div>
          </mat-slide-toggle>
        </div>
        <div class="w-full border border-[#bdbdbd] rounded-lg p-2 flex flex-col items-start">
          <label class="font-medium text-black">Departamento Padrão</label>
          <small class="text-zinc-400 font-light text-sm">
            Departamento que receberá os atendimentos que chegarem.
          </small>
          <mat-select class="input-field" formControlName="departmentDefault" placeholder="Selecione">
            <div class="input-select-filter w-full p-2 mb-2">
              <input class="input-field input-select-filter" type="text" (focus)="onFocusDepartmentDefault()"
                [formControl]="filterControlDepartmentDefault" placeholder="Pesquise por um departamento...">
            </div>
            @for (item of listDepartmentToDefault; track item) {
            <mat-option [value]="item._id">{{item.name}}</mat-option>
            }
          </mat-select>
        </div>
        <div class="w-full border border-[#bdbdbd] rounded-lg p-2 flex flex-col items-start">
          <mat-slide-toggle color="primary" formControlName="checkedIgnoreAttendance">
            <div class="flex flex-col items-start">
              <h1 class="text-base">Ignorar atendimento após quantos minutos</h1>
              <small class="text-zinc-400 font-light text-sm">
                Tempo em minutos que vai ser utilizado para ignorar a chegada de um novo atendimento.
              </small>
            </div>
          </mat-slide-toggle>
          @if (formGroup.get('checkedIgnoreAttendance')?.value) {
          <input class="input-field" formControlName="periodToIgnoreAttendance"
            placeholder="Digite um tempo em minutos...">
          }
        </div>
      </div>
    </div>
  </form>
</div>
<div class="flex flex-1 border-t-2 border-gray-200 justify-end items-center w-full gap-4 mt-3 mb-4">
  <button
    class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
    cdkFocusInitial (click)="onConfirmClick()" *ngIf="isAdmin()">Salvar</button>
</div>
}