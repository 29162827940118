import { Component, Inject, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, Observable, Subject, switchMap } from 'rxjs';
import { AbstractComponent } from '../../../../commons/abstract.component';
import { Pager } from '../../../../commons/pager';
import { Channel } from '../../../../models/channel.model';
import { Contact } from '../../../../models/contact.model';
import { ContactService } from '../../../../services/contact.service';

@Component({
  selector: 'contact-list-modal-component',
  templateUrl: './contact-list-modal.component.html',
  styleUrls: ['./contact-list-modal.component.scss'],
})
export class ContactListModalComponent extends AbstractComponent {
  pager: Pager<Contact> = new Pager<Contact>({ perPage: 10 });
  listObservable: Observable<Pager<Contact>>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  constructor(
    injector: Injector,
    private dialogRef: MatDialogRef<ContactListModalComponent>,
    private contactService: ContactService,
    @Inject(MAT_DIALOG_DATA) public data: { channel: Channel, block: boolean },
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getList();
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.contactService.getAll(this.pager.page, this.pager.perPage, term, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Contact>) => {
      this.pager = pager;
    });
  }

  search(termo: string) {
    this.pager.page = 1;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }

  getList() {
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    };
    this.contactService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
      next: (value) => {
        this.pager = value;
        this.setupObservableSearch();
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  select(item: Contact): void {
    this.dialogRef.close(item);
  }
}
